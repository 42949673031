import RepositoryBase, {handleAxiosError} from "@/repositories/repository-base";
import CompanyEntity from "@/entities/company-entity";
import {
  AcceptedSpecReconfirmationNotificationType, AcceptedSpecReconfirmationReminderType,
  AccessibilityToOtherDeptResources, ApprovalFlowSettingDeclineType,
  NutritionDecimalPoint
} from "@/entities/company-setting-entity";
import { HandledApiError } from "@/bootstraps/error-handler";
import {isMfaEmailEnabled} from "@/lang/help/ja/setting";

export class CompanyRepository extends RepositoryBase<CompanyEntity> {
  protected endpoint = "companies";
  protected ctor:new(data) => CompanyEntity = CompanyEntity;

  public async find(id:number): Promise<CompanyEntity> {
    return this.findById(id);
  }

  public async findByUid(uid:string, params = {}): Promise<ICompanyPublic|null> {
    try {
      const res = await this.get(`${this.endpoint}/uid:${uid}`, params);
      return res.data;
    } catch (e) {
      if (e instanceof HandledApiError && e.httpStatus === 404) {
        return null;
      }
      return handleAxiosError(e);
    }
  }

  public async updateSettingLabel(id:number, data:LabelSettingUpdateRequest) {
    return this.put(this.endpoint + `/${id}/settings/label`, data);
  }
  public async updateSettingSpec(id:number, data:SpecSettingUpdateRequest) {
    return this.put(this.endpoint + `/${id}/settings/spec`, data);
  }
}

export interface ICompanyPublic {
  id:number;
  uid:string;
  name:string;
}

export type CompanySettingUpdateRequestBase = {
  isAutoCreateIngredientFromSpec: boolean,
  isENumberVisible: boolean,
  isDepartmentEnabled: boolean;
  isAccessLogVisible: boolean;
  accessibilityToOtherDeptResources: AccessibilityToOtherDeptResources;
  departments: { id:number|null, name:string }[],
  passwordMinCharLength: number;
  passwordMinTypeLength: number;
  isMfaEmailEnabled: boolean;
  accessibleIpAddresses: string[];
}
export type SpecSettingUpdateRequest = CompanySettingUpdateRequestBase & {
  isApprovalFlowEnabled: boolean,
  isAcceptedSpecReconfirmationNotificationEnabled: boolean,
  acceptedSpecReconfirmationNotificationType: AcceptedSpecReconfirmationNotificationType,
  acceptedSpecReconfirmationNotificationDays: number | null,
  acceptedSpecReconfirmationReminderType: AcceptedSpecReconfirmationReminderType | null,
  specRequestDefaultRequiredFields: string[],
};
export type LabelSettingUpdateRequest = CompanySettingUpdateRequestBase & {
  decimalPlaceCalorie: NutritionDecimalPoint,
  decimalPlaceNonCalorie: NutritionDecimalPoint,
  useIntraCategory: boolean,
  displayDeprecatedNonGmoAsNonGmo: boolean,
  hiddenWhenAllSameNameIngredientInvisible: boolean;
  enableBrotherPrint: boolean;
};
